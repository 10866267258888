import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { PopupWrapper, useMessageBox } from '@hems/component';
import ChangeInstallerAccountDataGrid from '@hems/container/src/dataGrid/changeInstallerDataGrid/ChangeInstallerAccountDataGrid.vue';
import InstallerAccountSearchBox from '@hems/container/src/searchbox/InstallerAccountSearchBox.vue';
import { DeviceServiceAdmin } from '@hems/service';
import { useFailModal } from '@hems/util/src/composable';
import { STATUS_CODE } from '@hems/util/src/exception/exception';
export default defineComponent({
    name: 'ChangeInstallerPopup',
    components: {
        PopupWrapper,
        InstallerAccountSearchBox,
        ChangeInstallerAccountDataGrid,
    },
    props: {
        siteId: {
            type: Number,
            required: true,
        },
    },
    emits: ['close', 'afterSuccess'],
    setup(props, { emit }) {
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
        const state = reactive({
            searchCondition: {
                searchFor: undefined,
            },
            installerInfo: undefined,
        });
        function onSearch(condition) {
            state.searchCondition = condition;
        }
        function onSelect(installerInfo) {
            state.installerInfo = installerInfo;
        }
        const failModal = useFailModal({ content: t('message.save_server_error') });
        async function onOk() {
            if (!state.installerInfo) {
                messageBox.alert('There is not a selected item').open();
                return;
            }
            const { user_id, comn_nm, mpn_no, email } = state.installerInfo;
            const paddingTH = 'padding: 0.25rem 1rem;';
            const tableStyle = 'width: 100%; table-layout: fixed; word-break: break-all; height: auto;';
            if (!(await messageBox
                .confirm([
                'Are you sure you want to change to the selected item?',
                '',
                `
            <table style="${tableStyle}">
              <tbody>
                <tr><th style="${paddingTH}">- ${t('account.id')}</th><td>: ${user_id}</td></tr>
                <tr><th style="${paddingTH}">- ${t('common.company')}</th><td>: ${comn_nm}</td></tr>
                <tr><th style="${paddingTH}">- ${t('account.telephone')}</th><td>: ${mpn_no}</td></tr>
                <tr><th style="${paddingTH}">- ${t('account.email')}</th><td>: ${email}</td></tr>
              </tbody>
            </table>
            `,
            ])
                .alignMessage('left')
                .open())) {
                return;
            }
            try {
                const response = await deviceService.changeInstaller(props.siteId, {
                    installerId: state.installerInfo.user_id,
                });
                if (response.status === STATUS_CODE.OK) {
                    emit('afterSuccess', { ...state.installerInfo });
                    return;
                }
                failModal.open();
            }
            catch (error) {
                console.error(error);
                failModal.open();
                return;
            }
        }
        function onCancel() {
            emit('close');
        }
        return {
            state,
            onSearch,
            onSelect,
            onOk,
            onCancel,
        };
    },
});
